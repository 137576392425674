<script setup lang="ts">
import HomeCarousel from '../components/HomeCarousel.vue'
import getRecentPressReleaseQuery from '../graphql/getRecentPressRelease.gql'
import getUpcomingWebcastQuery from '../graphql/getUpcomingWebcast.gql'
import getLatestWebcastQuery from '../graphql/getLatestWebcast.gql'
import getLatestAnnualReportQuery from '../graphql/getLatestAnnualReport.gql'
import type { PressRelease } from './newsEvents/pressReleases/[year].vue'
import type { PresentationsWebcasts } from '~/typings/PresentationsWebcasts'
import { getFormattedPublishDate, getFormattedPublishDateTime } from '~/helpers/dateHelpers'
import { loadInovestorScript } from '~/helpers/scriptHelpers'
import { ensureProtocol } from '~/helpers/urlHelpers'

const localePath = useLocalePath()
const { locale, t } = useI18n()
const webcastDurationInHours = 4
const nowDate = new Date()

const { execute: getRecentPressRelease } = useApolloFetch(getRecentPressReleaseQuery, 'pressReleases')
const { execute: getUpcomingWebcast } = useApolloFetch(getUpcomingWebcastQuery, 'presentationAndWebcasts')
const { execute: getLatestWebcast } = useApolloFetch(getLatestWebcastQuery, 'presentationAndWebcasts')
const { execute: getLatestAnnualReport } = useApolloFetch(getLatestAnnualReportQuery, 'annualReports')

defineI18nRoute({
  paths: {
    'en-CA': '/home',
    'fr-CA': '/accueil'
  }
})

useHead({
  title: t('navigation.home.metatitle'),
  meta: [{ name: 'description', content: t('navigation.home.metadescription') }]
})

const _images = [
  { id: 1, url: t('home.banner.banner1.url'), alt: t('home.banner.banner1.alt') },
  { id: 2, url: t('home.banner.banner2.url'), alt: t('home.banner.banner2.alt') },
  { id: 3, url: t('home.banner.banner3.url'), alt: t('home.banner.banner3.alt') },
  { id: 4, url: t('home.banner.banner4.url'), alt: t('home.banner.banner4.alt') },
  { id: 5, url: t('home.banner.banner5.url'), alt: t('home.banner.banner5.alt') },
  { id: 6, url: t('home.banner.banner6.url'), alt: t('home.banner.banner6.alt') }
]

const images = ref(_images)
const recentPressRelease = ref<PressRelease>((await getRecentPressRelease({
  locale: locale.value
}))[0])
const upcomingWebcast = ref<PresentationsWebcasts>(
  (await getUpcomingWebcast({
    locale: locale.value,
    now: nowDate.toISOString()
  }))?.[0] ??
  (await getLatestWebcast({
    locale: locale.value
  }))?.[0]
)
const latestAnnualReport = (await getLatestAnnualReport({locale: locale.value}))?.[0]

function getWebcastPublishDate(webcast: PresentationsWebcasts): string {
  if (webcast.ScheduledPublishDate) {
    return getFormattedPublishDateTime(webcast.ScheduledPublishDate, locale.value)
  }

  return webcast.PublishedAtDate ? getFormattedPublishDateTime(webcast.PublishedAtDate, locale.value) : ''
}

function liveMeetingLinkIsAvailable(webcast: PresentationsWebcasts): boolean {
  if (!webcast.PublishedAtDate) {
    return true
  }

  const publishedDate = new Date(webcast.PublishedAtDate)
  const webcastEndTime = new Date(publishedDate.setHours(publishedDate.getHours() + webcastDurationInHours))

  return !!webcast.LiveMeetingUrl &&
    webcast.LiveMeetingUrl !== '' &&
    nowDate < webcastEndTime
}

onMounted(() => {
  loadInovestorScript(locale.value)
})
</script>

<template>
  <div class=" wrapContent">
    <div class="wrapper">
      <div>
        <home-carousel :images="images" />
      </div>
      <div class="blockNewsInvestor">
        <div class="news">
          <div class="defaultFacet">
            <div class="FacetedResultTemplate DefaultResultContainer">
              <a v-if="recentPressRelease"
                 target="_blank"
                 :href="recentPressRelease.Document.url">
                <div class="top">
                  <div class="date">
                    <span class="date">{{ getFormattedPublishDate(recentPressRelease.PublishedAtDate, locale) }}</span>
                  </div>
                  <div class="title">
                    <span class="">{{ recentPressRelease.Name }}</span>
                  </div>
                </div>
                <div class="readMore">
                  {{ t("home.readMore") }}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="sectionStock">
          <div class="stockWidget">
            <div>
              <div class="titleStock">
                <span>{{ t("home.stockInformation") }}</span>
              </div>
              <div id="box_bourse"
                   class="infoValue">
                <span class="tx_name">CAN</span>
                <span id="ino_last-price_0"
                      class="tx_val" /> <span id="ino_change-dollar_0"
                      class="inoIrPositiveValue" />
              </div>
              <p id="ino_delayed-info"
                 class="delayed" />
            </div>
            <a href="/investor-relations/shareholder-information/stock-chart"
               class="">
              {{ t("home.learnMore") }}
            </a>
          </div>
        </div>
        <div class="presentation">
          <a name="hyperlien"
             :href="ensureProtocol(latestAnnualReport.Document.url)"
             target="_blank"> 
            <img v-if="latestAnnualReport.Image" :src="latestAnnualReport.Image.url">
          </a>
        </div>
        <div class="infoInvestor vote">
          <div class="">
            <div class="titleInvestor">
              <span>{{ t("home.vote.agmVote") }}</span>
              <br>
              <span>{{ t("home.vote.accessinformation") }}</span>
            </div>
            <div class="dateInvestor">
              <strong><a name="hyperlien"
                   :href="t('home.vote.url')"
                   target="_blank">{{ t("home.vote.reminder") }}</a></strong>
              <br>
              <span>{{ t("home.vote.deadline") }}</span>
            </div>
            <NuxtLink :to="localePath('vote')"
                      class="widgetpresentation">
              {{ t("navigation.vote.name") }}
            </NuxtLink>
          </div>
        </div>
        <div class="infoInvestor">
          <div v-if="upcomingWebcast"
               class="FacetedResultTemplate DefaultResultContainer">
            <div class="titleInvestor">
              <span class="">{{ upcomingWebcast.Name }}</span>
            </div>
            <div class="dateInvestor">
              <span class="date inline">{{ getWebcastPublishDate(upcomingWebcast) }}</span>
              <p>{{ upcomingWebcast.Text }}</p>
              <div class="ProductBrokerType_RichText">
                <a v-if="liveMeetingLinkIsAvailable(upcomingWebcast)"
                   class="widgetWebcast"
                   target="_blank"
                   :href="upcomingWebcast.LiveMeetingUrl">{{ t("home.attendVirtualMeeting") }}</a>
                <a v-if="upcomingWebcast.WebcastUrl"
                   class="widgetWebcast"
                   target="_blank"
                   :href="upcomingWebcast.WebcastUrl">{{ t("home.listenToWebcast") }}</a>
                <a v-if="upcomingWebcast.Presentation"
                   class="widgetpresentation"
                   target="_blank"
                   :href="upcomingWebcast.Presentation.url">{{ t("home.viewPresentation") }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            {{ t("presentationsWebcasts.noUpcomingEvents") }}
          </div>
        </div>
      </div>
      <div class="block3">
        <div class="produits">
          <NuxtLink :id="t('home.idConsumerMarket')"
                    :to="localePath('products-consumerMarket')">
            <div class="image">
              <img src="~/assets/images/groupshot-allbrands2022.png"
                   alt="GroupShot_AllBrands4">
            </div>
            <div class="contenuBlock">
              <div class="titre">
                {{ t("navigation.consumerMarket.name") }}
              </div>
              <div class="viewMore">
                {{ t("home.viewMore") }}
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="produits">
          <NuxtLink :id="t('home.idAwayFromHomeMarket')"
                    :to="localePath('products-awayFromHomeMarket')">
            <div class="image">
              <img src="~/assets/images/away-from-home-market-2021.jpg"
                   alt="away-from-home-market-2021">
            </div>
            <div class="contenuBlock">
              <div class="titre">
                {{ t("navigation.awayFromHomeMarket.name") }}
              </div>
              <div class="viewMore">
                {{ t("home.viewMore") }}
              </div>
            </div>
          </NuxtLink>
        </div>
        <div class="produits">
          <NuxtLink :id="t('home.idPrivateLabelMarket')"
                    :to="localePath('products-privateLabelMarket')">
            <div class="image">
              <img src="~/assets/images/kpt-privatelabels-homepage.jpg"
                   alt="Private_label-Market">
            </div>
            <div class="contenuBlock">
              <div class="titre">
                {{ t("navigation.privateLabelMarket.name") }}
              </div>
              <div class="viewMore">
                {{ t("home.viewMore") }}
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.presentation.none {
  display: none !important
}

.presentation img {
  height: 100%;
}

.blockNewsInvestor {
  gap: 0.714rem;
}

.blockNewsInvestor .infoInvestor .titleInvestor {
  font-size: 1.286rem;
  line-height: 1.0;
}

.blockNewsInvestor .infoInvestor.vote {
  display: none;
}
</style>
